.create-room-button {
  height: 120px;
  width: 100%;
  font-size: 2em;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: auto;
  margin-top: 10%;
  color: rgba(255, 255, 255, 0.86);
}
.how-to-use li {
  font-size: 1.2em;
  margin-top: 12px;
}
@media only screen and (max-width: 750px) {
  .create-room-button {
    width: 100%;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: auto;
    margin-top: 10%;
    color: rgba(255, 255, 255, 0.86);
  }
}
