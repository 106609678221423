.stream-view-container {
  display: grid;
  align-items: flex-start;
  justify-items: stretch;
  grid-template-columns: [queue] 200px [player] auto [col4-start] 300px [end];
  gap: 0px 0px;
}

.queue-container {
  background: #404040;
  padding: 5px;
}
.video-player-container {
  background: #404040;
  padding: 5px;
  min-height: 100%;
}
.twchat-container {
  background: #404040;
  padding: 5px;
  min-height: 100%;
}
.top-info-container {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

@media only screen and (max-width: 750px) {
  .top-info-container {
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .stream-view-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, 100%);
  }
}
.control-user-panel {
  background: #404040;
}
