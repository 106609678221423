$primary: #6441a5;

.listContainer {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  min-height: 720px;
  height: 720px;
  background: #121212;
}

.queueImgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.5s ease;
  opacity: 0;
}
.imgContainer:hover .queueImgOverlay {
  display: block;
  background: red;
}
.listContainer::-webkit-scrollbar {
  width: 10px;
}

.nextButton {
  width: 75%;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.imageContainer {
  position: relative;
  width: 75px;
  height: 75px;
  margin: 0 auto;
}

.queueImage {
  transition: 0.5s ease;
  backface-visibility: hidden;
  margin: 5%;
  margin-left: 7%;
  margin-top: 7%;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: block;
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.imageContainer:hover .queueImage {
  opacity: 0.3;
}

.imageContainer:hover .middle {
  opacity: 1;
}
.currentlyPlaying {
  margin-bottom: 25px;
  border-radius: 50px;
  box-shadow: 0 2px 12px green;
  animation: breathing 20s infinite;
}

@keyframes breathing {
  0% {
    box-shadow: 2px 2px 4px 6px green;
  }
  50% {
    box-shadow: 2px 3px 8px 6px green;
  }
  100% {
    box-shadow: 1px 2px 4px 5px green;
  }
}
@media only screen and (max-width: 750px) {
  .listContainer {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    min-height: 0px;
    align-items: center;
    justify-content: space-between;
  }
  .nextButton {
    width: 100px;
    margin: 15px;
  }
  .imageContainer {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  .queueImage {
    transition: 0.5s ease;
    backface-visibility: hidden;
    width: 40px;
    height: 40px;
    border-radius: 0;
    margin-left: 12px;
    display: block;
    opacity: 1;
  }
  .currentlyPlaying {
    margin-right: 14px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    animation: none;
    padding-bottom: 3px;
    border-bottom: 3px solid green;
  }
  .nextButton {
    width: 100px;
    margin: 15px;
  }
  .cancel-text {
    margin: 0 auto;
  }
  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
  }
}
