html {
  background: #121212 !important;
  font-family: "Titillium Web", sans-serif;
}
html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: #121212 !important;
}
body {
  height: 100%;
}
button {
  border-radius: 0 !important;
}
::selection {
  background: #9146ff; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #9146ff; /* Gecko Browsers */
}
$primary: #6441a5;
.App {
  min-height: 100vh;
  background-color: #121212;
  color: white;
}
/* Nav Bar */
.navbarTitle {
  color: $primary;
}
/* Scroll Bar */
/* width */
html::-webkit-scrollbar {
  display: none;
}
.Browser::-webkit-scrollbar {
  width: 17px;
  position: absolute;
  left: 20;
}

/* Track */
.Browser::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
.Browser::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

/* Handle on hover */
.Browser::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.Browser {
  height: 90vh;
}
.pagination {
  justify-content: center;
}
/* Pagination */
.pagination-container {
}

ul.pagination {
  margin-left: auto;
  list-style: none;
  padding: 0;
  width: 500px;
  margin-bottom: 0px;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  // border: 1px solid rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  font-size: 25px;
  margin-left: 2px;
}

ul.pagination li a {
  text-decoration: none;
  color: #9146ff;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #9146ff;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: whitesmoke;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #9146ff;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
// Modals
.app-modal .modal-content {
  background: #121212;
  color: #9146ff;
  span {
    color: white;
    fill: white;
  }
}
.modal-body {
  border: 1px transparent;
}
.modal-header {
  border-bottom: 1px transparent !important;
}
.modal-footer {
  border-top: 1px transparent !important;
}
.nav.nav-tabs {
  border-bottom: 2px solid #6441a5;
}
a.nav-item.nav-link.active {
  background-color: #6441a5;
  color: rgba(255, 255, 255, 0.75);
  border: #6441a5;
  &:hover {
    border: 0px;
  }
}
a.nav-item.nav-link {
  &:hover {
    border: 1px solid #6441a5;
  }
}
.form-control {
  border-radius: 0px !important;
  background-color: #121212 !important;
  color: rgba(255, 255, 255, 0.75) !important;
  &:focus {
    box-shadow: none !important;
    border: 1px solid #6441a5;
  }
}
@import "./../node_modules/bootstrap/scss/bootstrap.scss";
@import "./styles/font-awesome/fontawesome.scss";
@import "./styles/font-awesome/solid.scss";
