.singleStream {
  margin-bottom: 10px;
}
.toTop {
  margin-left: 5px;
  border: 2px solid transparent;
  border-image: linear-gradient(to top left, #9146ff 0%, #9146ff 100%);
  border-image-slice: 1;
}
.browsingImage {
  opacity: 1;
  width: 100%;
  transition: transform 0.3s ease-in;
  border: 2px solid rgba($color: white, $alpha: 0.1);
  &:hover {
    transition: transform 0.2s ease-out;
    border: 2px solid rgba($color: white, $alpha: 0.7);
    transform: scale(1.01);
    z-index: 10;
    position: relative;
  }
}
.loader-wrapper {
  width: 100%;
}
.loader {
  border-radius: 50%;
  margin: 0 auto;
  &:before,
  &:after {
    border-radius: 50%;
  }

  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before,
  &:after {
    position: absolute;
    content: "";
  }

  &:before {
    width: 5.2em;
    height: 10.2em;
    background: #121212;
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 5.2em 5.1em;
    transform-origin: 5.2em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
  }

  &:after {
    width: 5.2em;
    height: 10.2em;
    background: #121212;
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 5.1em;
    -webkit-transform-origin: 0px 5.1em;
    transform-origin: 0px 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
  }
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.streamTitle {
  transition: padding 1s;
  font-size: 14px;
  border-radius: 2px;
  &:hover {
    cursor: default;
  }
}

.viewerCount {
  font-size: 14px;
  color: #6441a5;
  margin-left: 5px;
  display: inline;
}

.browserButtons {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
}

.queButton {
  position: absolute;
  width: calc(440px / 3);
  left: 0;
  top: 112px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.queButton a {
  width: 100px;
  padding: 114px 66px;
  text-align: center;
  color: white;
  z-index: 1;
  text-decoration: none;
}

.queButton a:hover {
  background-color: #9146ff;
  animation-name: color;
  cursor: pointer;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.playButton {
  position: absolute;
  width: calc(440px / 3);
  left: 146px;
  top: 112px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.playButton a {
  width: calc(440px / 3);
  padding: 114px 61px;
  text-align: center;
  color: white;
  /*   border: solid 2px #9146ff; */
  z-index: 1;
  text-decoration: none;
}

.playButton a:hover {
  background-color: #9146ff;
  animation-name: color;
  cursor: pointer;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.container {
  position: relative;
}
.previewcontainer {
  position: relative;
}

.previewcontainer:hover #preview_embed {
  opacity: 0.3;
}
.container:hover .browsingImage {
  opacity: 0.3;
}
.previewoverlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.previewcontainer:hover .previewoverlay {
  opacity: 1;
}
.container:hover .overlay {
  opacity: 1;
}
.buttons {
  width: 100%;
  display: flex;
  padding-left: 15px;
  flex-direction: row;
}
.buttons Button {
  color: #9146ff;
}
.switchStreamButton {
  position: absolute;
  left: 292px;
  top: 112px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.switchStreamButton a {
  width: calc(440px / 3);
  padding: 114px 66px;
  text-align: center;
  color: white;
  z-index: 1;
  text-decoration: none;
}

.switchStreamButton a:hover {
  background-color: #9146ff;
  cursor: pointer;
  animation-name: color;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

@keyframes color {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #9146ff;
  }
}
#preview_embed {
  left: 0;
  top: 0;
  height: 85%;
  width: 440px;
}
.container:hover .image {
  opacity: 0.3;
}

.container:hover .overlay {
  opacity: 1;
}
.container:hover #preview_embed {
  opacity: 1;
}
.titleContainer {
  width: 100%;
  color: #9146ff;
  padding: 12px;
  transition: padding 0.7s;
  &:hover {
    padding-left: 15px;
  }
}
.add-text {
  padding-right: 20px;
  font-weight: bolder;
  text-transform: uppercase;
  margin-left: 10px;
  color: rgba($color: white, $alpha: 0.7);
}
.stream-preview {
  background-color: #191919;
  height: 100%;
  padding: 10px;
  max-height: 90%;
}
.control-button {
  margin-top: 5px;
  &:last-child {
    margin-left: 5px;
  }
}
.browserButto::first-child {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.dropdown-categories {
  margin-left: 15px;
  margin-bottom: 15px;
}
.category-select {
  margin-left: 15px;
  margin-right: 10px;
  flex-grow: 0.3;
  text-align: left;
  svg {
    color: rgba($color: white, $alpha: 0.7);
    fill: rgba($color: white, $alpha: 0.7);
  }
  border: 2px solid transparent;
  border-image: linear-gradient(
    to top left,
    #9146ff 0%,
    #2c90fc 25%,
    #9146ff 50%,
    #2c90fc 75%,
    #9146ff 100%
  );
  border-image-slice: 1;
}
.see-more {
  width: 20rem;
  margin-left: 15px;
  border: 2px solid transparent;
  border-image: linear-gradient(
    to top left,
    #9146ff 0%,
    #2c90fc 25%,
    #9146ff 50%,
    #2c90fc 75%,
    #9146ff 100%
  );
  border-image-slice: 1;
}
@media only screen and (max-width: 750px) {
  .add-text {
    font-weight: bolder;
    font-size: 1.2em;
    text-transform: uppercase;
    color: rgba($color: white, $alpha: 0.7);
  }

  .browserButton {
    padding: 10px 0;
    width: 100%;
    padding: 10px;
    display: flex;
    text-align: center;
    margin: 0 auto;
    margin-top: 5px;
  }
  .browserButtons {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .add-text {
    display: none;
  }
  .see-more {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .toTop {
    padding: 10px 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    text-align: center !important;
    margin: 0 auto;
    margin-top: 5px;
  }
  .stream-preview {
    background-color: #191919;
    height: 100%;
    padding-bottom: 10%;
  }
  .category-select {
    margin: 0;
    width: 90%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
