.infoButton {
  width: 100px;
  margin-left: 16px;
}
@media only screen and (max-width: 750px) {
  .infoButton {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    width: 200px;
  }
}
